const menuContent = [
  {
    text: 'About me',
    route: '/',
  },
  {
    text: 'Projects',
    route: '/projects',
  },

];

export default menuContent;