const aboutContent = {
  greeting: "Hi there!",
  intro:
    "I'm Alina, an energetic web developer with a passion for building user-centered apps, a background in project and team management and a history of driving team growth through mentoring, training and good communication skills.",
  paragraphOne:
    "I’m a no nonsense kind of person, focused on teamwork and delivering useful solutions to end users. I’m a practitioner of open and honest communication and don’t shy away from giving or receiving feedback. My focus includes both self-development and team development, as continuous learning has proven to be the main ingredient for increasing efficiency in any setting I’ve worked in.",
  paragraphTwo:
    "I worked with the React ecosystem for 4+ years, but I'm always open to learn how to use other frameworks, languages or tools that can increase efficiency or results. I’m comfortable working with both legacy and greenfield projects and I have a proven track record of working with various code bases and custom setups.",
  paragraphThree:
    "My quiet time is spent crocheting, making tiny origami gardens, reading, going for walks and taking friends out for coffee.",
};

export default aboutContent;
